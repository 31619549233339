// 1. Import `extendTheme parameter ThemeOverride type`
import { ThemeOverride } from '@chakra-ui/react'

// 2. pass your custom values extends ThemeOverride
export const chakraExtendTheme: ThemeOverride = {
  colors: {
    pink: {
      50: '#e4007f',
      100: '#e4007f',
      200: '#e4007f',
      300: '#e4007f',
      400: '#e4007f',
      500: '#e4007f',
      600: '#e4007f',
      700: '#e4007f',
      800: '#e4007f',
      900: '#e4007f',
    },
  },
}
