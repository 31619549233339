import React, { useState } from 'react'
import { FaFacebookSquare, FaLine } from 'react-icons/fa'
import { MdOutlineArrowBackIos } from 'react-icons/md'
import styled from 'styled-components'

const StyledFooter = styled.div`
  background-color: #f8f8f8;
  padding: 30px 0 15px;
  * {
    list-style: none;
  }
  ß p {
    letter-spacing: 0.2px;
    font-family: 'Open Sans';
    src: url('fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1.5;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon {
    svg {
      display: inline;
    }
  }
  .d_col_2--5,
  .p_col_2--5 {
    width: 20%;
  }
  .m_col_12 {
    width: 100%;
  }
  @media (max-width: 767px) {
    .social_list .footer_nav_list {
      padding: 0 16px;
    }
  }
  .footer_title {
    font-size: 13px;
    color: #949494;
    margin-bottom: 5px;
    font-weight: bold;
    min-height: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      font-size: 17px;
      color: #373737;
      border-top: 1px solid #e0e0e0;
      padding: 15px 0;
    }
    i {
      display: none;
      transform: rotate(-90deg);
      @media (max-width: 767px) {
        display: inline-block;
      }
    }
    .icon_arrow {
      width: 20px;
      height: 20px;
      right: 28px;
      z-index: 25;
      text-align: center;
      font-size: 20px;
      border-radius: 50%;
      opacity: 0.9;
      cursor: pointer;
    }
    .icon_arrow.rotate {
      transition: all 0.3s linear;
      transform-origin: center center;
    }
    .icon_arrow.rotate-reverse {
      transform: rotate(90deg);
    }
  }
  .footer_title--nth1 {
    @media (max-width: 767px) {
      border-top: none;
    }
  }
  .footer_nav_list {
    transition: all 0.3s linear;
    @media (max-width: 767px) {
      padding: 0 16px 15px;
    }
    li {
      @media (max-width: 767px) {
        padding: 5px 0;
      }
      a {
        font-size: 0.9rem;
        color: #373737;
        @media (max-width: 767px) {
          font-size: 17px;
        }
        i {
          font-size: 15px;
          color: #656464;
        }
        &:hover {
          color: #e4007f;
        }
      }
    }
  }
    .btn_outlined--main {
      padding: 10px 15px;
      width: auto;
      font-size: 17px;
      border: 2px solid #e4007f;
      background-color: #fff;
      color: #e4007f;
      transition: 0.3s;
      border-radius: 6px;
      min-width: 100px;
      &:hover {
        transition: 0.3s;
        color: #fff;
        background-color: #e4007f;
      }
    }
  }
  .footer_copyright {
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    @media (max-width: 767px) {
      padding-bottom: 50px;
    }
    p {
      font-size: 0.75rem;
      color: #949494;
      line-height: 1.3;
      font-weight: 500;
    }
  }
  .slide_down {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .slide_down.slide_down-open {
    @media (max-width: 767px) {
      display: block;
    }
  }
`
const StyledContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  padding-right: 3%;
  padding-left: 3%;
  @media (max-width: 768px) {
    padding-right: 2%;
    padding-left: 2%;
  }
  @media (max-width: 767px) {
    padding-right: 15px;
    padding-left: 15px;
`
const StyledContainerGrid = styled.div`
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  @media (max-width: 768px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
`
const DefaultFooter: React.VFC = () => {
  const [isSlideDisplay, setIsSlideDisplay] = useState({
    product: false,
    about: false,
    service: false,
    social: false,
  })
  const [slideClassName, setslideStatus] = useState({
    product: '',
    about: '',
    service: '',
    social: '',
  })
  const toggleSlideStatus = (key: string) => {
    if (window.innerWidth > 767) return false
    const newSlideClassName = { ...slideClassName }
    const newSlideDisplayStatus = { ...isSlideDisplay }
    newSlideDisplayStatus[key] = !newSlideDisplayStatus[key]
    newSlideClassName[key] = newSlideDisplayStatus[key] ? 'slide_down-open' : ''
    setIsSlideDisplay(newSlideDisplayStatus)
    setslideStatus(newSlideClassName)
  }

  return (
    <StyledFooter id="footer" className="footer">
      <StyledContainer>
        <StyledContainerGrid>
          <div className="d_col_2--5 p_col_2--5 m_col_12">
            <p className="footer_title footer_title--nth1" onClick={() => toggleSlideStatus('product')}>
              產品與服務
              <i className={`icon_arrow rotate ${isSlideDisplay['product'] ? 'rotate-reverse' : ''}`}>
                <MdOutlineArrowBackIos />
              </i>
            </p>
            <ul className={`footer_nav_list slide_down ${slideClassName['product']}`}>
              <li>
                <a href="https://www.parenting.com.tw" target="_blank">
                  親子天下
                </a>
              </li>
              <li>
                <a href="https://shopping.parenting.com.tw/" target="_blank">
                  親子天下Shopping
                </a>
              </li>
              <li>
                <a href="https://site.parenting.com.tw/littlestar" target="_blank">
                  小行星
                </a>
              </li>
              <li>
                <a href="https://storyapp.parenting.com.tw/" target="_blank">
                  有聲故事書APP
                </a>
              </li>
              <li>
                <a href="https://learning.parenting.com.tw/" target="_blank">
                  線上學校
                </a>
              </li>
              <li>
                <a href="https://flipedu.parenting.com.tw/" target="_blank">
                  翻轉教育
                </a>
              </li>
              <li>
                <a href="https://bestbuy.parenting.com.tw" target="_blank">
                  親選好物
                </a>
              </li>
              <li>
                <a href="https://site.parenting.com.tw/home/podcast-203" target="_blank">
                  Podcast
                </a>
              </li>
            </ul>
          </div>
          <div className="d_col_2--5 p_col_2--5 m_col_12">
            <p className="footer_title" onClick={() => toggleSlideStatus('about')}>
              關於我們
              <i className={`icon_arrow rotate ${isSlideDisplay['about'] ? 'rotate-reverse' : ''}`}>
                <MdOutlineArrowBackIos />
              </i>
            </p>
            <ul className={`footer_nav_list slide_down ${slideClassName['about']}`}>
              <li>
                <a href="https://site.parenting.com.tw/home/aboutus-121" target="_blank">
                  關於我們
                </a>
              </li>
              <li>
                <a href="https://site.parenting.com.tw/home/club-216" target="_blank">
                  場地服務
                </a>
              </li>
              <li>
                <a href="https://cplink.co/publishing" target="_blank">
                  出版全目錄
                </a>
              </li>
              <li>
                <a href="https://site.parenting.com.tw/home/aboutad-21" target="_blank">
                  廣告合作
                </a>
              </li>
              <li>
                <a href="https://cplink.co/lecturerinvite" target="_blank">
                  講師邀約
                </a>
              </li>
              <li>
                <a href="https://site.parenting.com.tw/home/joinus-231" target="_blank">
                  人才招募
                </a>
              </li>
              <li>
                <a href="https://site.parenting.com.tw/home#contact" target="_blank">
                  聯絡我們
                </a>
              </li>
            </ul>
          </div>
          <div className="d_col_2--5 p_col_2--5 m_col_12">
            <p className="footer_title" onClick={() => toggleSlideStatus('service')}>
              服務條款
              <i className={`icon_arrow rotate ${isSlideDisplay['service'] ? 'rotate-reverse' : ''}`}>
                <MdOutlineArrowBackIos />
              </i>
            </p>
            <ul className={`footer_nav_list slide_down ${slideClassName['service']}`}>
              <li>
                <a href="https://www.parenting.com.tw/about/copyright" target="_blank">
                  著作權聲明
                </a>
              </li>
              <li>
                <a href="https://member.cwg.tw/register-rule" target="_blank">
                  會員服務條款
                </a>
              </li>
              <li>
                <a href="https://member.cwg.tw/privacy-policy" target="_blank">
                  隱私權政策
                </a>
              </li>
              <li>
                <a href="https://sites.google.com/cw.com.tw/authorization-parenting" target="_blank">
                  內容授權
                </a>
              </li>
              <li>
                <a href="https://learning.parenting.com.tw/terms" target="_blank">
                  使用者條款
                </a>
              </li>
            </ul>
          </div>
          <div className="d_col_5 p_col_5 m_col_12">
            <p className="footer_title" onClick={() => toggleSlideStatus('social')}>
              社群入口
              <i className={`icon_arrow rotate ${isSlideDisplay['social'] ? 'rotate-reverse' : ''}`}>
                <MdOutlineArrowBackIos />
              </i>
            </p>
            <div className={`footer_nav_list social_list slide_down ${slideClassName['social']}`}>
              <div className="d_col_6 p_col_6 m_col_12">
                <ul className="footer_nav_list">
                  <li>
                    <a href="https://cplink.co/QNfI1LB8" target="_blank">
                      <i className="icon">
                        <FaFacebookSquare />
                      </i>{' '}
                      親子天下線上學校FB
                    </a>
                  </li>
                  <li>
                    <a href="https://cplink.co/9xFaXSQf" target="_blank">
                      <i className="icon">
                        <FaLine />
                      </i>{' '}
                      親子天下線上學校LINE
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </StyledContainerGrid>
      </StyledContainer>
      <StyledContainer>
        <div className="footer_copyright">
          <p>服務專線：886-2-2662-0332 | 傳真電話：886-2-2662-6048 | 服務時間：週一～週五：9:00~17:30</p>
          <p>Copyright © 親子天下線上學校. All rights reserved. 版權所有，禁止擅自轉貼節錄</p>
        </div>
      </StyledContainer>
    </StyledFooter>
  )
}

export default DefaultFooter
